html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

main {
  width: 100vw;
  position: relative;
  background: radial-gradient(circle, rgba(255,255,224,1) 13%, rgba(249,233,170,1) 63%, rgba(238,219,142,1) 100%);
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
}

.flex_results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  top: 175px;
  height: 67vh;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: space-between;
}

.resultsHeader{
  position: relative;
  top: 130px;
  margin: 0 auto;
  width: max-content;
  font-family: 'Baloo', sans-serif;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  width: max-content;
  margin: 0 auto;
  align-items: center;
  top: 100px;
}

.titleDisplay {
  font-family: 'Baloo', sans-serif;
  height: max-content;
  position: relative;
  margin: 0 auto;
  top: 155px;
  font-size: 1.5rem;
  width: max-content;
}

.letho{
  font-size:2.5rem;
  font-family: 'Baloo', sans-serif;
  margin: 0 auto;
  display: block;
  width: max-content;
}

.desc {
  font-family: 'Oswald', sans-serif;
}

.overflow-scrolling {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 768px){
  .flex_results {
    justify-content: space-around;
  }
}

@media screen and (max-width: 414px) {
  .letho{
    font-size: 1.5rem;

  }
  .desc {
    font-size: .75rem;
  }

  .flex_results {
    padding: 0px;
  }
  .film-info {
    width: 255px;
    height: 240px;
  }



  .flex_filmInfo {
    width: 460px;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }

  .titleDisplay {
    font-size: .8rem;
  }

  img {
    width: 140px;
    height: 240px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 375px) {
  .letho{
    font-size: 1.5rem;

  }
  .desc {
    font-size: .6rem;
  }

  .film-info {
    width: 200px;
    height: 240px;
  }
}

@media screen and (max-width: 360px){
  .film-info{
    width: 150px;
  }
}
