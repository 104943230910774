.block-span {
  display: block;
  margin-bottom: 10px;
}

.film-info {
  width: 300px;
  height: 278px;
  overflow-y: scroll;
  padding-left: 15px;
  padding-right: 15px;
}

.tag, .overview, .date{
  font-family: 'Montserrat', sans-serif
}

.title {
  font-family: 'Baloo', sans-serif
}

.flex_filmInfo {
  width: 460px;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.no-results-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  width: 600px;
  height: 150px;
  margin: 0 auto;
}
